import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Typography } from '@material-ui/core';

import { StaticImage } from "gatsby-plugin-image"

import Download from "../components/download"

import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Pricipaldesk = () => (
  <Layout>
    <Seo title="Principal Desk" description="Principal Desk" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row>
        <Col xs={12} lg={8} className="bg-white">
        <div className="p-3 list-icon mt-4">
           <Typography variant="h4" gutterBottom style={{ color:'#453260' }}>From Principal's Desk</Typography>
           <div className="text-center pt-3 pb-3">

<Carousel fade indicators={false} controls={false} >
            <Carousel.Item>
            <StaticImage
                src="../images/prin.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr.B.R.AMBEDKAR COLLEGE OF LAW"
              />
            </Carousel.Item>
            <Carousel.Item>
            <StaticImage
                src="../images/prin2.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr.B.R.AMBEDKAR COLLEGE OF LAW"
              />
            </Carousel.Item>
            <Carousel.Item>
            <StaticImage
                src="../images/prin3.jpeg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr.B.R.AMBEDKAR COLLEGE OF LAW"
                
              />
            </Carousel.Item>
          
            <Carousel.Item>
            <StaticImage
                src="../images/prin4.jpeg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Dr.B.R.AMBEDKAR COLLEGE OF LAW"
                
              />
            </Carousel.Item>
          
          </Carousel>

        </div>
         <h6>Dear Prospective Student,</h6>
         <Typography variant="body2" paragraph>
         Let me congratulate you for taking a prudent decision of pursuing your 3 & 5 Year LL.B Degree in Law at the World Humanitarian Charitable Trust, Dr. B.R. Ambedkar College of Law-Affiliated Karnataka State Law University Recognized by the Government of Karnataka and approved by The Bar Council of India. May I Extend a very warm and Cordial Welcome to join LL.B Degree Course in our esteemed institution.
         </Typography>
         <Typography variant="body2" paragraph>
         The mission of the college is to achieve excellence in legal education and at the same time give predominance and pre-eminence to justice. The theme of this institution is “Let justice be done though the heaven fall”. As observed by Lord Mansfield. We not only provide excellence in teaching law but also instill and nurture the basic value of the legal professions and human dignity.
          </Typography>
         <Typography variant="body2" paragraph>
         I am confident that the Students of our Law College will certainly be in a better position to explore intricacies of law. Our faculty compressing of experienced, Dedicated Legal Luminaries will certainly mould and shape you to flourishing advocates in the days to come. We will leave no stone unturned in imparting knowledge on legal aspects of today’s complicated society. Our Future Law Markers will be ever ready to lend a helping hand to the needy and downtrodden to wage a fierce battle to get justice from the Mightiest.
          </Typography>
         <Typography variant="body2" paragraph>
         Let me assure you that we at Dr .B .R. Ambedkar College of Law will develop, and implement learning assessment, strengthen international opportunities, compete and implement core curriculum changes and continue development of technology with increased access. The Sky is the limit and for studying Law is an exciting and intriguing prospect.
          </Typography>
         <Typography variant="body2" paragraph>
         Those who wish to be the Legal Eagles of the Future are most welcome to join our “Learning – Assessment –Implementation-Oriented” institution. To help our develop at mind that is creative, open to new ideas and willing to think differently. To impart, a training that not only leads to a coveted law education, but also acts as a medium of intellectual stimulation, social change, prestige and career enhancement. To change the question “why Law?’ to” Why not Law?”
          </Typography>
         <Typography variant="body2" paragraph>
         I am looking forward to the next part of our collective journey and to our collective success.
          </Typography>
           
        </div>
        </Col>
        <Col xs={12} lg={4} className="bg-white">
        <Card className="shadow mb-2 bg-light mt-5">
  <Card.Body>
<Download/>
</Card.Body>
</Card>
 
</Col>

    </Row>
</Container>
</Box>

</ThemeProvider>
  </Layout>
)

export default Pricipaldesk
